<template>
    <div v-if="edtCartouche.version==1">
        <collapse-panel class="cartouche" :title="$t('note-editor.routine.execution')" :visible="executionPanelVisible" @expand="executionPanelVisible=true" @collapse="executionPanelVisible=false">
            <b-container>
                <score-row class="even" v-model="edtCartouche.controle" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.control")}}
                </score-row>
                <score-row class="odd" v-model="edtCartouche.utilisationFenetre" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.window")}}
                </score-row>
                <score-row class="even" v-model="edtCartouche.difficultesTechniques" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.technical_difficulties")}}
                </score-row>
                <score-row class="odd" v-model="edtCartouche.tricks" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.tricks")}}
                </score-row>
                <score-row class="even" v-model="edtCartouche.particularitesMultiLignes" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.multiline_specificities")}}
                </score-row>
                <score-row class="odd" v-model="edtCartouche.timing" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.timing")}}
                </score-row>
                <score-row class="even" v-model="edtCartouche.espacement" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.spacing") }}
                </score-row>                
            </b-container>
        </collapse-panel>
        <slot name="between-execution-and-content"></slot>
        <collapse-panel class="cartouche" :title="$t('note-editor.routine.content')" :visible="contentPanelVisible" @expand="contentPanelVisible=true" @collapse="contentPanelVisible=false" >
            <b-container>
                <score-row class="even" v-model="edtCartouche.complexite" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.complexity") }}
                </score-row>
                <score-row class="odd" v-model="edtCartouche.originalite" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.originality") }}
                </score-row>
                <score-row class="even" v-model="edtCartouche.creativite" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.creativity") }}
                </score-row>
                <score-row class="odd" v-model="edtCartouche.continuite" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.continuity") }}
                </score-row>
            </b-container>
        </collapse-panel>
        <slot name="between-content-and-comments"></slot>
         <collapse-panel title="Notes" :visible="commentsPanelVisible" @expand="commentsPanelVisible=true" @collapse="commentsPanelVisible=false">
            <b-textarea v-model="edtCartouche.comments" @change="emit_change"></b-textarea>
        </collapse-panel>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';
import ScoreRow from './ScoreDetailDecisionHelperRow.vue';

export default {
    components:{ CollapsePanel, ScoreRow },
    props: {
        value: { type: Object, required: true},
        readonly: { type: Boolean, default: false},
    },
    data(){
        return {
            edtCartouche: this.value,
            options: [
                { text: this.$t("note-editor.criteria-level.low"), value: "low"},
                { text: this.$t("note-editor.criteria-level.medium"), value: "medium"},
                { text: this.$t("note-editor.criteria-level.good"), value: "good"},
            ]         
        };
    },
    computed:{
      ...mapGetters('config', [ 'cfgRoutineNoteEditor_executionPaneVisible', 'cfgRoutineNoteEditor_contentPaneVisible', 'cfgRoutineNoteEditor_commentsPaneVisible']),

      executionPanelVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_executionPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_executionPaneVisible(newVal); }
        },
        contentPanelVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_contentPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_contentPaneVisible(newVal); }
        },
        commentsPanelVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_commentsPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_commentsPaneVisible(newVal); }
        },
    },
    methods:{
        ...mapMutations('config', [ 'setCfgRoutineNoteEditor_executionPaneVisible', 'setCfgRoutineNoteEditor_contentPaneVisible', 'setCfgRoutineNoteEditor_commentsPaneVisible']),
        emit_change(){
            this.$emit('change');
        }
    },
}
</script>

<style scoped>
/*    
    td{ padding-left: 10px; padding-right: 5px;}
    tr.even{ background-color:white;}
    tr.odd{ background-color:whitesmoke;}
*/
</style>
<style>
    td.note-detail{ display:flex; justify-content: flex-end;}
</style>